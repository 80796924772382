<template>
  <div class="checker-dialog">
    <w-dialog
      ref="dialogRef"
      title="退款管理员配置"
      width="50%"
      top="15vh"
      :hideFooter="true"
    >
      <div class="add-container">
        <div class="left-title">{{ tableTitle }}</div>
        <div class="right-search">
          <el-select
            v-model="userId"
            placeholder="请输入人员姓名、账号查询"
            filterable
            remote
            :remote-method="getUserOptions"
          >
            <el-option
              v-for="item in options"
              :key="item.u_uuid"
              :label="item.u_name"
              :value="item.u_uuid"
            >
              <span style="float: left">{{ item.u_name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.u_tel
              }}</span>
            </el-option>
          </el-select>
          <div
            class="add-btn"
            @click="handleAdd"
            v-if="authData.indexOf(authInfo.add) != -1"
          >
            添加人员
          </div>
        </div>
      </div>
      <el-scrollbar max-height="500px">
        <div
          class="people-info"
          v-for="(item, index) in peopleInfo"
          :key="index"
        >
          <div class="basic-info">
            <span style="color: var(--text-third-color);">{{ item.u_name }}</span>
            <span class="department" v-if="item.org.length" style="color: var(--text-third-color);">
              <template v-if="item.org.length > 3">
                <el-popover
                  effect="dark"
                  placement="top-start"
                  trigger="hover"
                  width="360px"
                  :content="item.org.join('、')"
                >
                  <template #reference>
                    <span class="text-hidden">
                      {{
                        item.org[0] +
                        "、" +
                        item.org[1] +
                        "、" +
                        item.org[2] +
                        "..."
                      }}
                    </span>
                  </template>
                </el-popover>
              </template>
              <template v-else>{{ item.org.join("、") }}</template>
            </span>
            <span class="phone" style="color: var(--text-third-color);">{{ item.u_tel }}</span>
          </div>
          <div class="delete-action">
            <div class="operator" v-if="operatorInfo && operatorInfo.u_name">
              {{ operatorInfo.u_name
              }}<span v-if="operatorInfo.check_date">{{
                operatorInfo.check_date
              }}</span
              >操作
            </div>
            <div
              class="delete-btn"
              @click="handleDelete(item)"
              v-if="authData.indexOf(authInfo.delete) != -1"
            >
              移除
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div v-if="!peopleInfo">
        <el-empty description="暂未配置检核人员"></el-empty>
      </div>
    </w-dialog>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { BasicApi, HotelApi, ScenicApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";

export default {
  emits: ["submit"],
  components: {},
  setup() {
    const store = useStore();
    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);
    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const dialogRef = ref(null);
    const userId = ref(null);
    const options = ref([]);

    function getUserOptions(query) {
      const lastParmas = {
        resources_id: id.value,
        resources_type: type.value,
      };
      if (query) {
        lastParmas["keyword"] = query;
      }
      BasicApi.userOptions(lastParmas).then((res) => {
        if (res.Code === 200) {
          options.value = res.Data || [];
        } else {
          let msg = res.Message ? res.Message : "获取所有人员数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    const tableTitle = ref("");
    const type = ref(null);
    const id = ref(null);
    const peopleInfo = ref({});
    const operatorInfo = ref({});

    function getDetail() {
      if (type.value == "hotel") {
        HotelApi.getHotelInfo({
          h_id: id.value,
        }).then((res) => {
          if (res.Code === 200) {
            peopleInfo.value = res.Data.checkUser;
            operatorInfo.value = res.Data.checkOperationUser
              ? res.Data.checkOperationUser
              : {};
            operatorInfo.value["check_date"] = res.Data.h_operation_check_date
              ? res.Data.h_operation_check_date
              : "";
          } else {
            let msg = res.Message ? res.Message : "获取酒店详情失败！";
            ElMessage.error(msg);
          }
        });
      } else if (type.value == "scenic") {
        ScenicApi.semanageInfo({
          s_id: id.value,
        }).then((res) => {
          if (res.Code === 200) {
            peopleInfo.value = res.Data.checkUser;
            operatorInfo.value = res.Data.checkOperationUser
              ? res.Data.checkOperationUser
              : {};
            operatorInfo.value["check_date"] = res.Data.s_operation_check_date
              ? res.Data.s_operation_check_date
              : "";
          } else {
            let msg = res.Message ? res.Message : "获取园区详情失败！";
            ElMessage.error(msg);
          }
        });
      } else if (type.value == "scenic_spot") {
        ScenicApi.spotEntranceInfo({
          sp_id: id.value,
        }).then((res) => {
          if (res.Code === 200) {
            peopleInfo.value = res.Data.checkUser;
            operatorInfo.value = res.Data.checkOperationUser
              ? res.Data.checkOperationUser
              : {};
            operatorInfo.value["check_date"] = res.Data.sp_operation_check_date
              ? res.Data.sp_operation_check_date
              : "";
          } else {
            let msg = res.Message ? res.Message : "获取景点详情失败！";
            ElMessage.error(msg);
          }
        });
      }
    }
    const authInfo = ref({});

    function openDialog(sendType, sendTitle, sendId, sendAuthInfo) {
      // hotel:酒店 scenic:园区 scenic_spot:园区
      userId.value = "";
      type.value = sendType;
      id.value = sendId;
      authInfo.value = sendAuthInfo;
      getUserOptions();
      getDetail();
      dialogRef.value.show();
      tableTitle.value = sendTitle;
    }

    function closeDialog() {
      dialogRef.value.close();
    }

    function handleAdd() {
      if (type.value == "hotel") {
        HotelApi.setHotelChecker({
          u_uuid: userId.value,
          h_id: id.value,
        }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("设置人员成功！");
            getDetail();
          } else {
            let msg = res.Message ? res.Message : "设置人员失败！";
            ElMessage.error(msg);
          }
        });
      } else if (type.value == "scenic") {
        ScenicApi.setScenicChecker({
          s_id: id.value,
          u_uuid: userId.value,
        }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("设置人员成功！");
            getDetail();
          } else {
            let msg = res.Message ? res.Message : "设置人员失败！";
            ElMessage.error(msg);
          }
        });
      } else if (type.value == "scenic_spot") {
        ScenicApi.setSpotChecker({
          sp_id: id.value,
          u_uuid: userId.value,
        }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("设置人员成功！");
            getDetail();
          } else {
            let msg = res.Message ? res.Message : "设置人员失败！";
            ElMessage.error(msg);
          }
        });
      }
    }

    function handleDelete(item) {
      if (type.value == "hotel") {
        HotelApi.deleteHotelChecker({
          h_id: id.value,
          u_uuid: item.u_uuid,
        }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("移除人员成功！");
            getDetail();
          } else {
            let msg = res.Message ? res.Message : "移除人员失败！";
            ElMessage.error(msg);
          }
        });
      } else if (type.value == "scenic") {
        ScenicApi.deleteSceniclChecker({
          s_id: id.value,
          u_uuid: item.u_uuid,
        }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("移除人员成功！");
            getDetail();
          } else {
            let msg = res.Message ? res.Message : "移除人员失败！";
            ElMessage.error(msg);
          }
        });
      } else if (type.value == "scenic_spot") {
        ScenicApi.deleteSpotChecker({
          sp_id: id.value,
          u_uuid: item.u_uuid,
        }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("移除人员成功！");
            getDetail();
          } else {
            let msg = res.Message ? res.Message : "移除人员失败！";
            ElMessage.error(msg);
          }
        });
      }
    }
    return {
      openDialog,
      dialogRef,
      closeDialog,
      tableTitle,
      userId,
      options,
      handleAdd,
      getUserOptions,
      peopleInfo,
      handleDelete,
      operatorInfo,
      authData,
      authInfo,
    };
  },
};
</script>

<style lang="scss">
.checker-dialog {
  .el-dialog__body {
    height: 600px;
  }

  .add-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;

    .right-search {
      display: flex;

      .el-select {
        width: 300px;
      }

      .add-btn {
        background-color: var(--btn-bg-color);
        border: 1px solid var(--btn-bg-color);
        color: var(--text-white-color);
        cursor: pointer;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .left-title {
      color: var(--text-color);
    }
  }

  .people-info {
    display: flex;
    justify-content: space-between;
    background-color: var(--theme-bg-color);
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 10px;

    .basic-info {
      > span {
        padding-right: 24px;
      }
    }

    .delete-action {
      display: flex;

      .operator {
        color: #cccccc;
      }

      .delete-btn {
        padding-left: 24px;
        color: var(--theme-color);
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
